import React, { lazy, Suspense,useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { handleMixpanelEvents, EVENTS } from "./utils/mixpanel";
import { jwtDecode } from 'jwt-decode';

const Unauthorized401 = lazy(() => import("./components/Error/Unauthorized401"))
const UserFlowCompleted = lazy(() => import("./components/stream/UserFlowCompleted"))
const Loader = lazy(() => import("./components/common/Loader"));
const StreamV3 = lazy(() => import("./components/stream/StreamV3"));
const UserWelcome = lazy(() => import("./components/Landing/UserWelcome"));
const Booth = lazy(() => import("./components/Landing/Booth"));
const PhoneCall =  lazy(() => import("./components/boothCompo/PhoneCall"))
const WhatsappScreen = lazy(() => import("./components/boothCompo/WhatsappScreen"))
const QRCodeStep = lazy(() => import("./components/boothCompo/QRCodeStep"))
const YellowAiBot = lazy(() => import("./components/boothCompo/YellowAiBot"))
const YellowAiBot2 = lazy(() => import("./components/boothCompo/YellowAiBot2"))
const AvatarList = lazy(() => import("./components/boothCompo/AvatarList"))

const RouterContainer = () => {
  const token = window.location.href.split("?token=")?.[1];
  const user = token ? jwtDecode(token) : "UNKNOWN";
  useEffect(() => {
    if(token){
      localStorage.setItem("access_token", token);
    }
  }, [token]);

  useEffect(() => {
    handleMixpanelEvents(EVENTS.VISITED, {"user": user});
  },[user]);

  
  return (
    <>
    <Router>
      <Suspense fallback={<Loader />}>
        <Routes>
          {/* <Route path="/" exact element={<UserWelcome />} /> */}

          <Route path="/" exact element={<Navigate to="/bot" />} />
          <Route path="/bot" exact element={<StreamV3 />} />
          <Route path="/booth" exact element={<Booth />} />
          <Route path="/phoneCall" exact element={<PhoneCall />} />
          <Route path="/whatsappScreen" exact element={<WhatsappScreen />} />
          <Route path="/qrCodeStep" exact element={<QRCodeStep />} />
          <Route path="/yellowAiBot" exact element={<YellowAiBot2 />} />
          <Route path="/completed" exact element={<UserFlowCompleted />} />
          {/* <Route path = "/401-unauthorized" exact element ={<Unauthorized401 />} /> */}

         
        </Routes>
      </Suspense>
    </Router>
    <ToastContainer position='top-right' />
    </>
  )
}

export default RouterContainer